import React, { Component } from "react"
import { isMobileOnly, isTablet } from "react-device-detect"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/animations/scroll.scss"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import FixedFacewallHomepage from "./index/FixedFacewallHomepage"
import ImageMeta from "../components/ImageMeta"

import StaticHero from "./index/StaticHero"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
import HomeCarousel from "./index/HomeCarousel"
import ImageText from "./index/ImageText"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import ReferringProvider from "./index/ReferringProvider"
import LearnMoreCards from "./index/LearnMoreCards"
import Exparel from "./index/Exparel"
import ASIRD from "./index/ASIRD"
import AAAHC from "./index/AAAHC"
import { TestimonialPreview } from "../components/Cards"
import TestimonialGrid from "../components/Cards/TestimonialGrid"
import ProgramToast from "../components/program-toast/ProgramToast"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      didScroll: false,
      scrollPosition: "relative",
      scrollOverflow: "unset",
      playing: false,
      extTheater: false,
      toggleCarouselsClass: false
    }
    this.toggleCarousel = this.toggleCarousel.bind(this)
    // this.window = window;

    // this.overrideTheater = this.overrideTheater.bind(this);
  }

  toggleCarousel() {
    var that = this
    that.setState({ toggleCarouselsClass: true })
    window.removeEventListener(
      "aos:in:home-section",
      this.toggleCarousel,
      false
    )
    window.removeEventListener(
      "aos:in:featured-carousel",
      this.toggleCarousel,
      false
    )
    window.removeEventListener("scroll", this.toggleCarousel, false)
  }

  componentDidMount() {
    //document.body.style.height = "102vh";
    //document.body.style.overflow = "scroll !important";
    window.addEventListener("aos:in:home-section", this.toggleCarousel)
    window.addEventListener("aos:in:featured-carousel", this.toggleCarousel)
    window.addEventListener("scroll", this.toggleCarousel)
  }

  render() {
    var pageContext = this.props.pageContext
    var post
    var language = this.props.pageContext.language

    if (pageContext && pageContext.language) {
      switch (language) {
        case "en":
          post = this.props.data.allUniquePagesJson.nodes[0]
          break
        case "es":
          post = this.props.data.spanishIndex.nodes[0]

          break
        default:
          post = this.props.data.allUniquePagesJson.nodes[0]
      }
    } else {
      post = this.props.data.allUniquePagesJson.nodes[0]
    }

    var staticStyle = {
      position: this.state.scrollPosition,
      overflow: this.state.scrollOverflow,
      width: "100%",
      zIndex: 1
    }
    var sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    let allReviews = []

    const rvs = this.props.data.allReviews.nodes

    // match this with Cards.scss if amount changes
    if (isMobileOnly) {
      allReviews = rvs.splice(0, 18)
    } else if (isTablet) {
      allReviews = rvs.splice(0, 48)
    } else {
      allReviews = rvs
    }

    console.log(allReviews.length)

    const reviewsHalf = allReviews.length / 2

    if (language === "es") allReviews = this.props.data.allReviewsEs.nodes
    let sortedReviews = sortReviewsByDate(allReviews)

    //shuffleArray(allReviews);
    let fixedFacewallReviews = sortedReviews.slice(0, 8)

    return (
      <SharedStateProvider>
        <Layout
          className={`main-homepage ${language === "es" ? "es" : null}`}
          language={language}
          layoutClass={"homepage-fab"}
          noFab={true}
          pageTitle={"main-homepage"}
          toastText={
            language === "en" ? (
              <>
                <span className="whole-div">
                  <Link to="/wisdom/"></Link>
                </span>
                <div className="toast-message-container">
                  <a>
                    Need your wisdom teeth removed? Apply for our Wisdom For
                    Wisdom program.{" "}
                    <span className="bold-underline">Learn&nbsp;More</span>
                  </a>
                </div>
              </>
            ) : null
          }
        >
          <SEO
            lang={language}
            title={post.metaTitle}
            description={post.metaDescription}
            schemaPage={post.schemaPage}
            pathname={this.props.location.pathname}
          />

          <div className="static-container" style={staticStyle}>
            {post.hasSizzle ? (
              <Sizzle
                playing={this.state.playing}
                sizzleButtonStyle={sizzleButtonStyle}
                post={post}
                youtube={post.sizzleYoutube}
                language={language}
              >
                <StaticHeading
                  playing={this.state.playing}
                  sizzleButtonStyle={sizzleButtonStyle}
                  heading={post.mobileHeading}
                  post={post}
                  language={language}
                  youtube={post.sizzleYoutube}
                />
              </Sizzle>
            ) : (
              <StaticHero post={post} />
            )}

            <LearnMoreCards language={language} post={post.learnMoreCards} />

            {language == "en" && (
              <>
                <div className="columns">
                  {post.featuredReviews.map(card => (
                    <div key={card.youtube} className="column">
                      <TestimonialPreview
                        language={language}
                        youtube={card.youtube}
                        image={card.image}
                        heading={card.heading}
                        subheading={card.subHeading}
                      />
                    </div>
                  ))}
                </div>

                <div className="testimonial-wall">
                  {allReviews.map((t, idx) => (
                    <TestimonialPreview
                      hoverLeft={idx + 1 > reviewsHalf}
                      withHoverCard
                      key={t.youtube}
                      language={language}
                      youtube={t.youtube}
                      image={t.mainPhotoPublicId}
                      reviewerName={t.reviewerName}
                      city={t.city}
                      procedure={t.mainProcedureDone}
                    />
                  ))}
                </div>
              </>
            )}

            <ImageText language={language} post={post} textImage noReverse />

            {language === "es" && (
              <FixedFacewallHomepage
                language={language}
                heading={
                  language === "es"
                    ? "Escuche Acerca de Nuestros Pacientes"
                    : "Hear From Our Patients"
                }
                reviews={fixedFacewallReviews}
              />
            )}

            <Exparel post={post.homeExparel} />

            <InTheCommunity
              colorBack={false}
              language={language}
              data={post.inTheCommunity}
            />

            <ReferringProvider
              colorBack={true}
              language={language}
              data={post.referringProvider}
            />

            <ASIRD post={post.homeAsird} />

            <AAAHC post={post.homeAAAHC} language={language} />

            <FirstTime
              colorBack={false}
              language={language}
              post={post}
              noReverse
            />

            <AllRatingsReviews
              colorBack={true}
              animation
              heading={post.homeRatingsReviews.heading}
              language={language}
              buttonHref={post.homeRatingsReviews.buttonOne.href}
              buttonText={post.homeRatingsReviews.buttonOne.buttonText}
            />
          </div>

          <ProgramToast endDate="January 27 2024" program="custom" />
        </Layout>
      </SharedStateProvider>
    )
  }
}

IndexPage.propTypes = {
  pageContext: PropTypes.object
}

export const pageQuery = graphql`
  query reviewsIndexQuery {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { ne: "ESP" } }
      limit: 100
    ) {
      nodes {
        ...FacewallData
      }
    }
    allReviewsEs: allSpanishReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ESP" } }
      limit: 4
    ) {
      nodes {
        title
        reviewerName
        reviewType
        reviewLanguage
        thumbnailPublicId
        mainProcedureDone
        monthYear {
          month
          year
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        hasSizzle
        mobileHeading
        intro
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        featuredReviews {
          heading
          subHeading
          youtube
          image
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          imageId
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          heading
          text
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        svgSubheading
        schemaPage
      }
    }
    spanishIndex: allSpanishUniquePagesJson(filter: { title: { eq: "/es/" } }) {
      nodes {
        hasSizzle
        mobileHeading
        intro
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          imageId
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          heading
          text
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        svgSubheading
        schemaPage
      }
    }
  }
`

export default IndexPage
