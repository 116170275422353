import React from "react"
import Carousel from "../../components/carousel/comFeaturedCarousel"


function HomeCarousel(props) {

    return (
        <div id="homeCar">
            <section className="home-section short-home">
                <div
                    className="container"
                    data-aos="fade-up"
                    data-aos-id="home-section"
                    data-aos-duration="1200"
                >
                    <div className="columns">
                        <div className="column is-4"></div>
                        <div className="column">
                            <h3>
                                {props.language === "es"
                                    ? "Escuche de Nuestros Pacientes"
                                    : "Hear From Our Patients"}
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
            <div
                className={
                    "carousel-section-match" +
                    " carousel-" +
                    props.toggleCarouselsClass
                }
            // style={{ paddingBottom: "100px" }}
            >
                <Carousel
                    slideID="featuredCarousel"
                    data-aos="no-anim"
                    data-aos-offset="400"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="0"
                    data-aos-id="featured-carousel"
                    language={props.language}
                />
            </div>
        </div>
    )
}

export default HomeCarousel