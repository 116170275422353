import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"

function InTheCommunity(props) {
  if (!props.data.hasThisSection) {
    return <></>
  }

  const mainClasses = classNames(
    "in-the-community",
    "show-phone-only-inverse",
    "is-vcentered",
    {
      "color-back": props.colorBack
    }
  )

  const mainMobileClasses = classNames(
    "in-the-community",
    "show-phone-only-block",
    {
      "color-back": props.colorBack
    }
  )

  return (
    <>
      <HorizontalTwoColumns
        className={mainClasses}
        leftColWidth={3}
        rightColWidth={3}
        middleColWidth={1}
        leftColumn={
          <div
            className="column"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <ImageMeta
              loading="lazy"
              cloudName="nuvolum"
              publicId={props.data.imageId}
              data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.data.imageId}`}
              responsive
            />
          </div>
        }
        rightColumn={
          <div
            className="column community-text-block"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <h3>{props.data.subheading}</h3>
            <h2>{props.data.heading}</h2>
            <p className="large">{props.data.text}</p>
            <ButtonGroupMap buttons={props.data.buttons} />
          </div>
        }
      />
      <HorizontalTwoColumns
        className={mainMobileClasses}
        leftColWidth={2}
        rightColWidth={2}
        middleColWidth={1}
        leftColumn={
          <div className="column">
            <h3>{props.data.subheading}</h3>
            <h2>{props.data.heading}</h2>
            <p className="large">{props.data.text}</p>
            <ImageMeta
              loading="lazy"
              cloudName="nuvolum"
              publicId={props.data.imageId}
              data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.data.imageId}`}
              responsive
            />
            <ButtonGroupMap buttons={props.data.buttons} />
          </div>
        }
        rightColumn={<div></div>}
      />
    </>
  )
}

export default InTheCommunity
