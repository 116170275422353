import React, { useState } from "react"
import { Link } from "gatsby"

import "./ProgramToast.scss"

//Props
// href = page url
// endDate = program application end date, format = month day year (September 23 2021)
// program = program name ex. "smile again"

const ProgramToast = ({ href, endDate = "January 2, 2100", program }) => {
  program = program.toLowerCase().trim()

  const [close, setClose] = useState(false)

  const today = new Date()
  const end = new Date(endDate)

  const dateWords = endDate.split(" ")
  const displayDate = `${dateWords[0]} ${dateWords[1]}`

  if (close || !program || today > end) return <></>

  // program prop needs to match the name of a key
  const programs = {
    custom: (
      <p>
        Click{" "}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScO4vdslC_NTq4XvUpTN37HlaPbhu-8-8yzBdvT86S8oAyuLg/viewform"
          target="_blank"
        >
          HERE
        </a>{" "}
        to register for our upcoming Continuing Education Event on 1/27!
      </p>
    ),
    "smile again": (
      <p>
        Apply to the <Link to={href}>Smile Again</Link> program by {displayDate}{" "}
        for a chance to receive a new smile.
      </p>
    ),
    "second chance": (
      <p>
        Apply to the <Link to={href}>Second Chance</Link> program by{" "}
        {displayDate} for a chance to receive a new smile.
      </p>
    ),
    "smiles for soldiers": (
      <p>
        We're providing two active duty soldiers or military veterans with a new
        smile. <Link to={href}>Apply by {displayDate}!</Link>
      </p>
    ),
    "share a smile": (
      <p>
        Apply to the <Link to={href}>Share A Smile</Link> program by{" "}
        {displayDate} for a chance to receive a new smile.
      </p>
    ),
    wisdom: (
      <p>
        We're providing FREE wisdom teeth removal surgery to a local student.{" "}
        <Link to={href}> Apply before {displayDate}!</Link>
      </p>
    ),
    edu: (
      <p>
        We're hosting a healthcare symposium for students on {displayDate}.{" "}
        <Link to={href}>Sign up here!</Link>
      </p>
    ),
    alwaysapril: (
      <p>
        Free Oral Cancer Screening: Friday, April 28.{" "}
        <Link to={href}>SIGN UP TODAY!</Link>
      </p>
    )
  }

  return (
    <div className="program-toast">
      <button
        aria-label="close banner"
        className="close-toast"
        onClick={() => setClose(true)}
      >
        ✖
      </button>
      {programs[program]}
    </div>
  )
}

export default ProgramToast
