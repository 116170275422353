import React from 'react'

import CenterColumn from "../../components/Bulma/CenterColumn"
import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Button from '@bit/azheng.joshua-tree.button';


const Exparel = ({ post }) => {
    if (!post.hasThisSection) return <></>
    
    return (
        <CenterColumn
            className="home-asird"
            leftColWidth={0}
            rightColWidth={0}
            centerColumn={
                <div className="column asird-grid">
                <ImageMeta
                    data-aos="fade-right" 
                    data-aos-duration="1200"
                    cloudName="nuvolum"
                    publicId={post.imageId}
                    responsive
                />
                <div
                    data-aos="fade-left" 
                    data-aos-duration="1200"
                >
                    <MarkdownViewer markdown={post.blurb} />
                    <div className="button-row">
                        <Button
                            contained
                            href={post.button.href}
                            buttonText={post.button.buttonText}
                        />
                    </div>
                </div>
                </div>
            }
            />
    )
}

export default Exparel